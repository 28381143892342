import React, { FC, MouseEventHandler } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';
import ArchiveModal from 'shared/component/Modal/ArchiveModal';
import { useStore } from 'store';

type PropsT = {
    id: string;
    title: string;
    actionArchive: (id: string) => Promise<void>;
};

const ArchiveButton: FC<PropsT> = (props) => {
    const {
        id, title, actionArchive,
    } = props;
    const { onOpen } = useStore('archiveItemModal');

    const handleButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        onOpen(id || 'id');
    };

    const onArchiveButtonClick = () => {
        actionArchive(id).then();
    };

    return (
        <>
            <Tooltip title="Перенести в архив" placement="top">
                <IconButton onClick={ handleButtonClick }>
                    <DeleteIcon color="error" />
                </IconButton>
            </Tooltip>
            <ArchiveModal
                id={ id || 'id' }
                title={ title }
                onArchiveButtonClick={ onArchiveButtonClick }
            />
        </>
    );
};

export default ArchiveButton;
