import { action, makeObservable, observable } from 'mobx';

import BaseModalStore from './BaseModalStore';

export default class DisplayColumnsModal extends BaseModalStore {
    public selectedColumns: string[] = [];

    public setSelectedColumns(columns: string[]) {
        this.selectedColumns = columns;
    }

    constructor() {
        super();
        makeObservable(this, {
            selectedColumns: observable,
            setSelectedColumns: action.bound,
        });
    }
}
