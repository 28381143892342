import { GridColumnVisibilityModel } from '@mui/x-data-grid-premium';
import { action, makeAutoObservable } from 'mobx';
import { CheckUpdatePriceRateItems } from 'shared/graphql/mutation/price/CheckUpdatePriceRateAction';
import { CourtTypeT } from 'types/CourtType';
import { PriceT } from 'types/Price';
import { PriceSeasonT } from 'types/PriceSeason';
import { PriceTypeT } from 'types/PriceType';
import { ServiceCategoryT } from 'types/ServiceCategory';

export default class PriceStore {
    public columnVisibilityModel: GridColumnVisibilityModel = {};

    public priceList: PriceT[] = [];

    public priceSeason: PriceSeasonT | undefined = undefined;

    public priceTypeList: PriceTypeT[] = [];

    public pricePreviewForMultipleRateUpdate: CheckUpdatePriceRateItems = [];

    public price: PriceT | undefined = undefined;

    public count: number = 0;

    public activePriceType: PriceTypeT | undefined = undefined;

    public courtTypes: CourtTypeT[] = [];

    public serviceCategories: ServiceCategoryT[] = [];

    constructor() {
        makeAutoObservable(this, {
            setColumnVisibilityModel: action.bound,
        }, {
            autoBind: true,
        });
    }

    public get prices(): PriceT[] {
        return this.priceList;
    }

    public get priceTypes(): PriceTypeT[] {
        return this.priceTypeList;
    }

    public setPrices(prices: PriceT[], count?: number) {
        this.priceList = prices;
        this.count = count ?? 0;
    }

    public setPriceTypes(priceTypes: PriceTypeT[]) {
        this.priceTypeList = priceTypes;
    }

    public setPriceSeason(priceSeason: PriceSeasonT) {
        this.priceSeason = priceSeason;
    }

    public setPricePreviewForMultipleRateUpdate(
        pricePreviewForMultipleRateUpdate: CheckUpdatePriceRateItems,
    ) {
        this.pricePreviewForMultipleRateUpdate = pricePreviewForMultipleRateUpdate;
    }

    public setActivePriceType(priceType: PriceTypeT) {
        this.activePriceType = priceType;
    }

    public setColumnVisibilityModel(model: GridColumnVisibilityModel) {
        this.columnVisibilityModel = model;
    }
}
