import { gql } from '@apollo/client';

import { PaymentUpdatePayloadT } from '../payment/PaymentUpdate';

export type PaymentDocumentUpdatePayloadT = {
    id: string;
    number: string;
    datetime: Date;
    organization_id: string;
    comment: string | null;
    is_archive: boolean;
    payments: {
        data: PaymentUpdatePayloadT[];
        on_conflict: {
            constraint: string;
            update_columns: string[];
        };
    };
};

export type PaymentDocumentUpdateResponseDataT = {
    updatePaymentDocument: {
        id: string;
    };
} | null | undefined;

export type PaymentDocumentUpdateVarT = {
    onConflict: {
        constraint: string;
        update_columns: string[];
    };
    paymentDocumentPayload: PaymentDocumentUpdatePayloadT;
}

export const PAYMENT_DOCUMENT_UPDATE = gql`
    mutation updatePaymentDocument(
        $onConflict: payment_document_on_conflict = {}
        $paymentDocumentPayload: [payment_document_insert_input!]!,
    ) {
        updatePaymentDocument: insert_payment_document(
            on_conflict: $onConflict
            objects: $paymentDocumentPayload,
        ) {
            affected_rows
        }
    }
`;
