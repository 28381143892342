import {
    DATA_GRID_INITIAL_STATE,
    DATA_GRID_PAGE_SIZES,
    DATA_GRID_SELECTION_COL_HEADER_DATA,
} from 'constants/dataGrid';

import * as React from 'react';
import {
    FC, memo, useCallback, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import {
    GridColumnGroupingModel,
    GridColumns, GridColumnVisibilityModel,
    GridFilterModel,
    GridLinkOperator,
    GridPinnedRowsProp,
    GridRowClassNameParams,
    GridRowsProp,
    GridSelectionModel,
    GridSortModel,
    ruRU,
} from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { observer } from 'mobx-react';
import DisplayColumnsIcon from 'shared/component/Icons/DisplayColumn';
import { DataGridPropsT } from 'types/DataGrid';

import { getDetailPanelContent } from './domain/getDetailPanelContent';
import { getDetailPanelHeight } from './domain/getDetailPanelHeight';
import { getQueryParams } from './domain/getQueryParams';
import { setQueryParams } from './domain/setQueryParams';
import { DataGridWrap, StyledDataGrid } from './domain/Styled';
import ActionsMenu from './ActionsMenu';

type PropsT = {
    rows: GridRowsProp;
    rowCount: number;
    columns: GridColumns;
    loading: boolean;
    pinnedRows?: GridPinnedRowsProp;
    columnGroupingModel?: GridColumnGroupingModel;
    detailPanelColumns?: GridColumns;
    multipleDeleteAction?: Function;
    multipleRefreshAction?: Function;
    className?: string;
    rowAutoHeight?: boolean;
    hideFooter?: boolean;
    initialState?: GridInitialStatePremium;
    getRowClassName?: (params: GridRowClassNameParams<any>) => string;
    showColumns?: () => void;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: (model: GridColumnVisibilityModel) => void;
    rowsPerPageOptions?: number[];
} & DataGridPropsT;

const DataGrid: FC<PropsT> = observer((props) => {
    const {
        rows,
        rowCount,
        columns,
        loading,
        pinnedRows,
        pageSize,
        setPageSize,
        page,
        setPage,
        setSortModel,
        setFilterModel,
        sortModel,
        filterModel,
        columnGroupingModel,
        detailPanelColumns,
        multipleDeleteAction,
        multipleRefreshAction,
        className,
        rowAutoHeight,
        hideFooter,
        initialState = DATA_GRID_INITIAL_STATE,
        getRowClassName,
        showColumns,
        columnVisibilityModel,
        rowsPerPageOptions = DATA_GRID_PAGE_SIZES,
        onColumnVisibilityModelChange,
    } = props;
    const [shouldUpdateUrl, setShouldUpdateUrl] = useState(false);
    const [filterLinkOperator, setFilterLinkOperator] = useState<GridLinkOperator>(
        GridLinkOperator.And,
    );
    const navigate = useNavigate();
    const [rowSelectionModel, setRowSelectionModel] = useState<GridSelectionModel>([]);
    const [initialParams] = useState({
        sortModel,
        filterModel,
        page,
        pageSize,
    });

    const detailPanelContent = detailPanelColumns
        ? getDetailPanelContent(detailPanelColumns)
        : undefined;
    const detailPanelHeight = detailPanelColumns ? getDetailPanelHeight() : undefined;
    const gridColumns = [
        ...columns,
        ...(multipleDeleteAction ? [DATA_GRID_SELECTION_COL_HEADER_DATA] : []),
    ];

    const handleFilterModelChange = useCallback((filter: GridFilterModel) => {
        setFilterModel({ ...filter });
        setShouldUpdateUrl(true);
    }, []);

    const handleSortModelChange = useCallback((sort: GridSortModel) => {
        setSortModel([...sort]);
        setShouldUpdateUrl(true);
    }, []);

    const handlePageSizeChange = (newPageSize: number) => {
        setPageSize(newPageSize);
        setShouldUpdateUrl(true);
    };

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
        setShouldUpdateUrl(true);
    };

    const resetAllParams = () => {
        handlePageChange(initialParams.page);
        handleFilterModelChange(initialParams.filterModel);
        handlePageSizeChange(initialParams.pageSize);
        handleSortModelChange(initialParams.sortModel);
    };

    useEffect(() => {
        setRowSelectionModel([]);
    }, [rows]);

    useEffect(() => {
        getQueryParams(
            setPage,
            setPageSize,
            handleSortModelChange,
            handleFilterModelChange,
            filterLinkOperator,
            setFilterLinkOperator,
        );
    }, []);

    useEffect(() => {
        setQueryParams(
            shouldUpdateUrl,
            sortModel,
            filterModel,
            page,
            pageSize,
            setShouldUpdateUrl,
            navigate,
        );
    }, [shouldUpdateUrl]);

    return (
        <DataGridWrap className="data-grid-wrap">
            <Stack
                spacing={ 2 }
                direction="row"
                sx={ {
                    position: 'absolute',
                    right: 0,
                    top: '-43px',
                } }
            >
                { showColumns && (
                    <Button size="small" variant="outlined" startIcon={ <DisplayColumnsIcon /> } onClick={ showColumns }>
                        Отобразить столбцы
                    </Button>
                ) }
                <Button
                    size="small"
                    variant="outlined"
                    onClick={ () => {
                        resetAllParams();
                    } }
                >
                    Сбросить фильтры
                </Button>
                { (multipleDeleteAction || multipleRefreshAction) && (
                    <ActionsMenu
                        deleteAction={ multipleDeleteAction }
                        refreshAction={ multipleRefreshAction }
                        disabled={ !rowSelectionModel.length }
                        selectedRows={ rowSelectionModel }
                    />
                ) }
            </Stack>
            <StyledDataGrid
                rows={ rows }
                pinnedRows={ pinnedRows }
                columns={ gridColumns }
                className={ className }
                pagination
                getRowHeight={ () => (rowAutoHeight ? 'auto' : undefined) }
                getRowClassName={ getRowClassName }
                getEstimatedRowHeight={ () => (rowAutoHeight ? 200 : null) }
                rowsPerPageOptions={ rowsPerPageOptions }
                sortingMode="server"
                paginationMode="server"
                filterMode="server"
                page={ page }
                pageSize={ pageSize }
                rowCount={ rowCount }
                filterModel={ filterModel }
                sortModel={ sortModel }
                onSortModelChange={ handleSortModelChange }
                onPageSizeChange={ handlePageSizeChange }
                onFilterModelChange={ handleFilterModelChange }
                onPageChange={ handlePageChange }
                loading={ loading }
                localeText={ ruRU.components.MuiDataGrid.defaultProps.localeText }
                columnGroupingModel={ columnGroupingModel }
                experimentalFeatures={ {
                    columnGrouping: true,
                    rowPinning: true,
                } }
                hideFooter={ hideFooter }
                initialState={ initialState }
                getDetailPanelHeight={ detailPanelHeight }
                getDetailPanelContent={ detailPanelContent }
                disableRowGrouping
                keepNonExistentRowsSelected
                checkboxSelection={ !!multipleDeleteAction }
                disableSelectionOnClick
                selectionModel={ rowSelectionModel }
                onSelectionModelChange={ (newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                } }
                columnVisibilityModel={ columnVisibilityModel }
                onColumnVisibilityModelChange={ (model) => onColumnVisibilityModelChange?.(model) }
            />
        </DataGridWrap>
    );
});

export default memo(DataGrid);
