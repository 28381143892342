import {
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridAlignment,
    GridFilterModel,
    GridLinkOperator,
    GridSortModel,
} from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';

export const DATA_GRID_PAGE_SIZE_SMALL = 25;
export const DATA_GRID_PAGE_SIZE_MIDDLE = 50;
export const DATA_GRID_PAGE_SIZE_BIG = 100;
export const DATA_GRID_PAGE_SIZE_HUGE = 1000;

export const DATA_GRID_PAGE_SIZES = [
    DATA_GRID_PAGE_SIZE_SMALL,
    DATA_GRID_PAGE_SIZE_MIDDLE,
    DATA_GRID_PAGE_SIZE_BIG,
];

export const DATA_GRID_PAGE_SIZES_EXTEND = [
    ...DATA_GRID_PAGE_SIZES,
    DATA_GRID_PAGE_SIZE_HUGE,
];

export const DATA_GRID_SORT_MODEL_INITIAL_STATE: GridSortModel = [];
export const DATA_GRID_FILTER_MODEL_INITIAL_STATE: GridFilterModel = {
    items: [],
    linkOperator: GridLinkOperator.And,
};

export const DATA_GRID_INITIAL_PAGE = 0;

export const PAGE_PARAM = 'page';
export const PAGE_SIZE_PARAM = 'pageSize';
export const FILTER_LINK_OPERATOR_PARAM = 'linkOperator';
export const SORT_PARAM = 'sort';
export const FILTER_PARAM = 'filter';

export const COLOR_DELETE_OPERATION = 'error';
export const COLOR_DUPLICATE_OPERATION = 'info';

export const EMPTY_CELL = '—';

export const DATA_GRID_INITIAL_STATE: GridInitialStatePremium = {
    pinnedColumns: { right: ['actions', '__check__'] },
};
export const COURT_VIDEO_ANALYTICS_DATA_GRID_INITIAL_STATE: GridInitialStatePremium = {
    pinnedColumns: { left: ['timeInterval'] },
};

export const PAYMENT_DATA_GRID_INITIAL_STATE: GridInitialStatePremium = {
    pinnedColumns: { right: ['total', 'actions'] },
};

export const PRICE_DATA_GRID_INITIAL_STATE: GridInitialStatePremium = {
    pinnedColumns: { left: ['category', 'courtType', 'day', 'timeStart', 'timeEnd', 'groupView', 'coachCategory'], right: ['actions', '__check__'] },
};

export const DATA_GRID_SELECTION_COL_HEADER_DATA = {
    field: GRID_CHECKBOX_SELECTION_COL_DEF.field,
    width: 80,
    disableColumnMenu: true,
    sortable: false,
    align: 'center' as GridAlignment,
};
