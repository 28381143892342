// Login
export const ERROR_LOGIN_COMMON = 'При авторизации произошла неизвестная ошибка';
export const ERROR_LOGIN_INCORRECT_USERNAME_OR_PASSWORD = 'Неверные имя пользователя или пароль';
export const SUCCESS_LOGIN = 'Вы успешно авторизовались';

// PriceType updating
export const ERROR_PRICE_TYPE_UPDATE = 'Невозможно изменить тип прайса';
export const SUCCESS_PRICE_TYPE_UPDATE = 'Тип прайса успешно изменен';
// PriceType creating
export const ERROR_PRICE_TYPE_CREATE = 'Невозможно создать тип прайса';
export const SUCCESS_PRICE_TYPE_CREATE = 'Тип прайса успешно создан';
// PriceType deleting
export const ERROR_PRICE_TYPE_DELETE = 'Тип прайса не найден или при удалении произошла ошибка';
export const SUCCESS_PRICE_TYPE_DELETE = 'Тип прайса успешно удален';

// PriceSalesRound updating
export const ERROR_PRICE_SALES_ROUND_UPDATE = 'Невозможно изменить раунд продаж';
export const SUCCESS_PRICE_SALES_ROUND_UPDATE = 'Раунд продаж успешно изменен';
// PriceSalesRound creating
export const ERROR_PRICE_SALES_ROUND_CREATE = 'Невозможно создать раунд продаж';
export const SUCCESS_PRICE_SALES_ROUND_CREATE = 'Раунд продаж успешно создан';
// PriceSalesRound deleting
export const ERROR_PRICE_SALES_ROUND_DELETE = 'Невозможно удалить раунд продаж';
export const SUCCESS_PRICE_SALES_ROUND_DELETE = 'Раунд продаж успешно удален';

// Price updating
export const ERROR_PRICE_UPDATE = 'Невозможно изменить прайс';
export const SUCCESS_PRICE_UPDATE = 'Прайс успешно изменен';
// Price multiple creating
export const ERROR_PRICE_MULTIPLE_CREATE = 'Невозможно создать прайсы';
// Price deleting
export const ERROR_PRICE_DELETE = 'Прайс не найден или при удалении произошла ошибка';
export const SUCCESS_PRICE_DELETE = 'Прайс успешно удален';

// PriceSeason updating
export const ERROR_PRICE_SEASON_UPDATE = 'Невозможно изменить сезон';
export const SUCCESS_PRICE_SEASON_UPDATE = 'Сезон успешно изменен';
// PriceSeason creating
export const ERROR_PRICE_SEASON_CREATE = 'Невозможно создать сезон';
export const SUCCESS_PRICE_SEASON_CREATE = 'Сезон успешно создан';
// PriceSeason deleting
export const ERROR_PRICE_SEASON_DELETE = 'Сезон не найден или при удалении произошла ошибка';
export const SUCCESS_PRICE_SEASON_DELETE = 'Сезон успешно удален';
// PriceSeason duplicating
export const ERROR_PRICE_SEASON_DUPLICATE = 'Сезон не найден или при дублировании произошла ошибка';
export const SUCCESS_PRICE_SEASON_DUPLICATE = 'Сезон успешно продублирован';
// PriceSeason checking
export const ERROR_PRICE_SEASON_CHECK = 'При проверке сезона произошла ошибка';

// Price Rate updating
export const ERROR_PRICE_RATE_UPDATE = 'Невозможно заполнить прайсы';
export const SUCCESS_EMPTY_PRICE_RATE_UPDATE = 'В результате операции ни одна строка не была изменена';

// MemberCardType updating
export const ERROR_MEMBER_CARD_TYPE_UPDATE = 'Невозможно изменить вид членской карты';
export const SUCCESS_MEMBER_CARD_TYPE_UPDATE = 'Вид членской карты успешно изменен';
// MemberCardType creating
export const ERROR_MEMBER_CARD_TYPE_CREATE = 'Невозможно создать вид членской карты';
export const SUCCESS_MEMBER_CARD_TYPE_CREATE = 'Вид членской карты успешно создан';
// MemberCardType deleting
export const ERROR_MEMBER_CARD_TYPE_DELETE = 'Вид членской карты не найден или при удалении произошла ошибка';
export const SUCCESS_MEMBER_CARD_TYPE_DELETE = 'Вид членской карты успешно удален';
export const ERROR_MEMBER_CARD_EXP_DATE_EARLIER_THAN_CURRENT = 'Новая дата истечения не должна быть раньше текущей';
export const ERROR_MEMBER_CARD_CURRENT_DATE_LATER_THAN_EXP = 'Новая дата начала не должна быть позднее даты истечения';
// MemberCard deleting
export const ERROR_MEMBER_CARD_DELETE = 'Членская карта не найдена или при удалении произошла ошибка';
export const SUCCESS_MEMBER_CARD_DELETE = 'Членская карта успешно удалена';
// MemberCard creating
export const ERROR_MEMBER_CARD_CREATE = 'При создании членской карты произошла ошибка';
export const SUCCESS_MEMBER_CARD_CREATE = 'Членская карта успешно создана';
// MemberCard switch isActive
export const ERROR_MEMBER_CARD_SWITCH_IS_ACTIVE = 'При деактивации членской карты произошла ошибка';
export const SUCCESS_MEMBER_CARD_SWITCH_IS_ACTIVE = 'Членская карта успешно деактивирована';

// PriceMemberCardType updating
export const ERROR_PRICE_MEMBER_CARD_TYPE_UPDATE = 'При изменении типа карты произошла ошибка';
export const SUCCESS_PRICE_MEMBER_CARD_TYPE_UPDATE = 'Тип карты успешно изменен';

export const ERROR_DELETE = 'При удалении произошла ошибка';

// MemberCard Reason messages
export const ERROR_CLIENT_NOT_FOUND = 'Клиент не найден';
export const ERROR_CLIENT_BALANCE_INSUFFICIENT = 'Недостаточно средств на балансе';
export const ERROR_CARD_ALREADY_CREATED = 'Членская карта уже создана (клиенту разрешено иметь только одну членскую карту)';
export const ERROR_MEMBER_CARD_TYPE_SEASON_PRICE_NOT_FOUND = 'Не указана цена для типа карты на указанный сезон';
export const ERROR_MEMBER_CARD_UPDATE = 'Произошла ошибка при продлении членской карты';
export const SUCCESS_MEMBER_CARD_UPDATE = 'Продление членской карты выполнено успешно';

// Schedule creating messages
export const ERROR_PRICE_SEASON_DATE_OVERLAP = 'Данный сезон пересекается по датам с другим активным сезоном';
export const ERROR_SCHEDULE_CREATE = 'При генерации расписания произошла ошибка';
export const SUCCESS_SCHEDULE_CREATE = 'Задание на генерацию расписания успешно запущено';
export const SUCCESS_SCHEDULE_RECREATE = 'Расписание успешно обновлено';
export const ERROR_SCHEDULE_RECREATE = 'При обновлении расписания произошла ошибка';

export const ERROR_SERVICE_APP_UPDATE = 'Невозможно обновить настройки приложения';
export const SUCCESS_SERVICE_APP_UPDATE = 'Настройки приложения успешно обновлены';

// ServiceCategory deleting
export const ERROR_SERVICE_CATEGORY_DELETE = 'При удалении категории произошла ошибка';
export const SUCCESS_SERVICE_CATEGORY_DELETE = 'Категория успешно удалена';

// ServiceCategory creating
export const ERROR_SERVICE_CATEGORY_CREATE = 'При создании категории произошла ошибка';
export const SUCCESS_SERVICE_CATEGORY_CREATE = 'Категория успешно создана';

// ServiceCategory updating
export const ERROR_SERVICE_CATEGORY_UPDATE = 'При обновлении категории произошла ошибка';
export const SUCCESS_SERVICE_CATEGORY_UPDATE = 'Категория успешно обновлена';

// CourtType update
export const ERROR_COURT_TYPE_UPDATE = 'Не удалось обновить настройки типа корта';
export const SUCCESS_COURT_TYPE_UPDATE = 'Настройки типа корта успешно обновлены';

// CourtType delete
export const ERROR_COURT_TYPE_DELETE = 'Не удалось удалить настройки типа корта';
export const SUCCESS_COURT_TYPE_DELETE = 'Настройки типа корта успешно удалены';

// CourtType create
export const ERROR_COURT_TYPE_CREATE = 'Не удалось создать настройки типа корта';
export const SUCCESS_COURT_TYPE_CREATE = 'Настройки типа корта успешно созданы';

// Court update
export const ERROR_COURT_UPDATE = 'Не удалось обновить корт';
export const SUCCESS_COURT_UPDATE = 'Корт успешно обновлен';

// Court create
export const ERROR_COURT_CREATE = 'Не удалось создать корт';
export const SUCCESS_COURT_CREATE = 'Корт успешно создан';

// Court delete
export const ERROR_COURT_DELETE = 'Не удалось удалить корт';
export const SUCCESS_COURT_DELETE = 'Корт успешно удален';

// CourtCategory update
export const ERROR_COURT_CATEGORY_UPDATE = 'Не удалось обновить категорию корта';
export const SUCCESS_COURT_CATEGORY_UPDATE = 'Категория корта успешно обновлена';

// CourtCategory create
export const ERROR_COURT_CATEGORY_CREATE = 'Не удалось создать категорию корта';
export const SUCCESS_COURT_CATEGORY_CREATE = 'Категория корта успешно создана';

// CourtCategory delete
export const ERROR_COURT_CATEGORY_DELETE = 'Не удалось удалить категорию корта';
export const SUCCESS_COURT_CATEGORY_DELETE = 'Категория корта успешно удалена';

// PaymentMethod update messages
export const ERROR_PAYMENT_METHOD_UPDATE = 'При обновлении метода оплаты произошла ошибка';
export const SUCCESS_PAYMENT_METHOD_UPDATE = 'Метод оплаты успешно обновлен';

// PaymentMethod create messages
export const ERROR_PAYMENT_METHOD_CREATE = 'При создании метода оплаты произошла ошибка';
export const SUCCESS_PAYMENT_METHOD_CREATE = 'Метод оплаты успешно создан';

// PaymentMethod delete messages
export const ERROR_PAYMENT_METHOD_DELETE = 'При удалении метода оплаты произошла ошибка';
export const SUCCESS_PAYMENT_METHOD_DELETE = 'Метод оплаты успешно удален';

// PaymentType update messages
export const ERROR_PAYMENT_TYPE_UPDATE = 'При обновлении типа оплаты произошла ошибка';
export const SUCCESS_PAYMENT_TYPE_UPDATE = 'Тип оплаты успешно обновлен';

// PaymentType create messages
export const ERROR_PAYMENT_TYPE_CREATE = 'При создании типа оплаты произошла ошибка';
export const SUCCESS_PAYMENT_TYPE_CREATE = 'Тип оплаты успешно создан';

// PaymentType delete messages
export const ERROR_PAYMENT_TYPE_DELETE = 'При удалении типа оплаты произошла ошибка';
export const SUCCESS_PAYMENT_TYPE_DELETE = 'Тип оплаты успешно удален';

// Organization update
export const ERROR_ORGANIZATION_UPDATE = 'Не удалось обновить реквизиты организации';
export const SUCCESS_ORGANIZATION_UPDATE = 'Реквизиты организации успешно обновлены';

// Organization create
export const ERROR_ORGANIZATION_CREATE = 'Не удалось создать реквизиты организации';
export const SUCCESS_ORGANIZATION_CREATE = 'Реквизиты организации успешно созданы';

// Organization delete
export const ERROR_ORGANIZATION_DELETE = 'Не удалось удалить реквизиты организации';
export const SUCCESS_ORGANIZATION_DELETE = 'Реквизиты организации успешно удалены';

// OrganizationLocation update
export const ERROR_ORGANIZATION_LOCATION_UPDATE = 'Не удалось обновить локацию организации';
export const SUCCESS_ORGANIZATION_LOCATION_UPDATE = 'Локация организации успешно обновлена';

// OrganizationLocation create
export const ERROR_ORGANIZATION_LOCATION_CREATE = 'Не удалось создать локацию организации';
export const SUCCESS_ORGANIZATION_LOCATION_CREATE = 'Локация организации успешно создана';

// OrganizationLocation delete
export const ERROR_ORGANIZATION_LOCATION_DELETE = 'Не удалось удалить локацию организации';
export const SUCCESS_ORGANIZATION_LOCATION_DELETE = 'Локация организации успешно удалена';

// Group create messages
export const ERROR_GROUP_VIEW_CREATE = 'При создании вида группы произошла ошибка';
export const SUCCESS_GROUP_VIEW_CREATE = 'Вид группы успешно создан';

// Group update messages
export const ERROR_GROUP_VIEW_UPDATE = 'При обновлении вида группы произошла ошибка';
export const SUCCESS_GROUP_VIEW_UPDATE = 'Вид группы успешно обновлен';

// Group delete messages
export const ERROR_GROUP_VIEW_DELETE = 'При удалении вида группы произошла ошибка';
export const SUCCESS_GROUP_VIEW_DELETE = 'Вид группы успешно удален';

// CoachCategory update messages
export const ERROR_COACH_CATEGORY_UPDATE = 'При обновлении категории тренера произошла ошибка';
export const SUCCESS_COACH_CATEGORY_UPDATE = 'Категория тренера успешно изменена';

// CoachCategory create messages
export const ERROR_COACH_CATEGORY_CREATE = 'При создании категории тренера произошла ошибка';
export const SUCCESS_COACH_CATEGORY_CREATE = 'Категория тренера успешно создана';

// CoachCategory delete messages
export const ERROR_COACH_CATEGORY_DELETE = 'При удалении категории тренера произошла ошибка';
export const SUCCESS_COACH_CATEGORY_DELETE = 'Категория тренера успешно удалена';

// coach create message
export const ERROR_COACH_CREATE = 'При создании тренера произошла ошибка';
export const SUCCESS_COACH_CREATE = 'Тренер успешно создан';

// coach update message
export const ERROR_COACH_UPDATE = 'При изменении тренера произошла ошибка';
export const SUCCESS_COACH_UPDATE = 'Тренер успешно изменен';

// coach delete message
export const ERROR_COACH_DELETE = 'При удалении тренера произошла ошибка';
export const SUCCESS_COACH_DELETE = 'Тренер успешно удален';

// AcquiringSetting creating
export const ERROR_ACQUIRING_SETTING_CREATE = 'При создании настройки эквайринга произошла ошибка';
export const SUCCESS_ACQUIRING_SETTING_CREATE = 'Настройка эквайринга успешно создана';

// AcquiringSetting deleting
export const ERROR_ACQUIRING_SETTING_DELETE = 'Настройка эквайринга не найдена или при удалении произошла ошибка';
export const SUCCESS_ACQUIRING_SETTING_DELETE = 'Настройка эквайринга успешно удалена';

// Acquiring Setting updating
export const ERROR_ACQUIRING_SETTING_UPDATE = 'Произошла ошибка при редактировании настройки эквайринга';
export const SUCCESS_ACQUIRING_SETTING_UPDATE = 'Настройка эквайринга успешно изменена';

// PaymentDocument update archive
export const ERROR_PAYMENT_DOCUMENT_UPDATE = 'Произошла ошибка при редактировании документа';
export const SUCCESS_PAYMENT_DOCUMENT_UPDATE = 'Документ успешно измненен';
