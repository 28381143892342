import { array, object, string } from 'yup';

export type DisplayColumnsFormFields = {
    columns: string[];
};

export const DISPLAY_COLUMNS_DEFAULT_VALUES: DisplayColumnsFormFields = {
    columns: [],
};

export enum DISPLAY_COLUMNS_FIELDS_KEYS {
    columns = 'columns',
}

export const DISPLAY_COLUMNS_VALIDATION_SCHEMA = object().shape({
    columns: array().of(string()),
});
