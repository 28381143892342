import { FC } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import Grid2 from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { PaymentDocumentFormFields } from 'page/PaymentDocument/components/formData';
import DatePicker from 'shared/component/Form/DatePicker';
import Select from 'shared/component/Form/Select';
import TextField from 'shared/component/Form/TextField';
import { useStore } from 'store';
import { PaymentT } from 'types/Payment';

import { PAYMENT_FIELDS_KEYS, PAYMENT_LABEL_DATA } from './formData';

type PropsT = {
    index: number;
    control: Control<PaymentDocumentFormFields>;
    payment: PaymentT;
    setValue: UseFormSetValue<PaymentDocumentFormFields>;
}

const PaymentFields: FC<PropsT> = observer((props) => {
    const {
        control, index, payment, setValue,
    } = props;
    const { getFilterOption } = useStore('filterOptions');
    const paymentMethodFilterOptions = getFilterOption('paymentMethod');
    const paymentTypeFilterOptions = getFilterOption('paymentType');

    return (
        <Grid2 container xs={ 12 } columnSpacing={ 2 } rowSpacing={ 2 }>
            <Grid2 xs={ 6 }>
                <TextField
                    control={ control }
                    name={ `payments.${index}.${PAYMENT_FIELDS_KEYS.comment}` }
                    label={ PAYMENT_LABEL_DATA.comment }
                />
            </Grid2>
            <Grid2 xs={ 6 } alignSelf="flex-end">
                <DatePicker
                    label={ PAYMENT_LABEL_DATA.datetime }
                    name={ `payments.${index}.${PAYMENT_FIELDS_KEYS.datetime}` }
                    setValue={ setValue }
                    value={ dayjs(payment.datetime) }
                    control={ control }
                    fullWidth={ true }
                    size="medium"
                />
            </Grid2>
            <Grid2 xs={ 6 }>
                <TextField
                    control={ control }
                    name={ `payments.${index}.${PAYMENT_FIELDS_KEYS.paymentInFiat}` }
                    label={ PAYMENT_LABEL_DATA.paymentInFiat }
                />
            </Grid2>
            <Grid2 xs={ 6 }>
                <TextField
                    control={ control }
                    name={ `payments.${index}.${PAYMENT_FIELDS_KEYS.paymentInBonus}` }
                    label={ PAYMENT_LABEL_DATA.paymentInBonus }
                />
            </Grid2>
            <Grid2 xs={ 6 }>
                <Select
                    options={ paymentMethodFilterOptions }
                    control={ control }
                    name={ `payments.${index}.${PAYMENT_FIELDS_KEYS.paymentMethod}.id` }
                    label={ PAYMENT_LABEL_DATA.paymentMethod }
                />
            </Grid2>
            <Grid2 xs={ 6 }>
                <Select
                    options={ paymentTypeFilterOptions }
                    control={ control }
                    name={ `payments.${index}.${PAYMENT_FIELDS_KEYS.paymentType}.id` }
                    label={ PAYMENT_LABEL_DATA.paymentType }
                />
            </Grid2>
        </Grid2>
    );
});

export default PaymentFields;
