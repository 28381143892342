import { BONUS, FIAT } from 'constants/currency';
import { OPERATION_CANCEL, OPERATION_TRANSFER } from 'constants/operations';
import {
    PROPERTY_FORWARD_TRANSFER_COST_BACK_TRANSFER_COST,
    PROPERTY_MIN_RATE, PROPERTY_PERFORMER_RETURN_INCOME_VALUE,
    PROPERTY_PERFORMER_SALE_INCOME_VALUE, PROPERTY_RETURN_RATE,
    PROPERTY_SALE_RATE, PROPERTY_TRANSFER_DISABLED_BEFORE_HOURS,
} from 'constants/propertyNames';

export const COLUMN_OPTIONS = [
    { value: 'saleRate', label: PROPERTY_SALE_RATE },
    { value: 'shareOfPaymentFiat', label: FIAT },
    { value: 'shareOfPaymentBonus', label: BONUS },
    { value: 'minRate', label: PROPERTY_MIN_RATE },
    { value: 'performerSaleIncomeValue', label: PROPERTY_PERFORMER_SALE_INCOME_VALUE },
    { value: 'returnRate', label: PROPERTY_RETURN_RATE },
    { value: 'performerReturnIncomeValue', label: PROPERTY_PERFORMER_RETURN_INCOME_VALUE },
    { value: 'cancellable', label: OPERATION_CANCEL },
    { value: 'transferable', label: OPERATION_TRANSFER },
    { value: 'transferDisabledBeforeHours', label: PROPERTY_TRANSFER_DISABLED_BEFORE_HOURS },
    { value: 'transferCost', label: PROPERTY_FORWARD_TRANSFER_COST_BACK_TRANSFER_COST },
];
