import { MODAL_TITLE_DISPLAY_COLUMNS } from 'constants/modalTitles';
import { PROPERTY_DISPLAY_COLUMNS } from 'constants/propertyNames';

import React, { FC, useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import MultipleSelect from 'shared/component/Form/MultipleSelect';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useDisplayColumns from 'shared/hook/displayColumns/display/useDisplayColumns';
import { useStore } from 'store';

import { COLUMN_OPTIONS } from '../../domain/columnOptions';

import {
    DISPLAY_COLUMNS_DEFAULT_VALUES, DISPLAY_COLUMNS_FIELDS_KEYS,
    DISPLAY_COLUMNS_VALIDATION_SCHEMA,
    DisplayColumnsFormFields,
} from './formData';

const DisplayColumnsModal: FC = observer(() => {
    const {
        isOpen, onClose, selectedColumns, setSelectedColumns,
    } = useStore('displayColumns');
    const [loading, setLoading] = useState(false);
    const {
        control, handleSubmit, reset, formState: { isValid },
    } = useForm<DisplayColumnsFormFields>({
        defaultValues: DISPLAY_COLUMNS_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(DISPLAY_COLUMNS_VALIDATION_SCHEMA),
    });
    const { field: columnsField } = useController({ control, name: DISPLAY_COLUMNS_FIELDS_KEYS.columns });
    const { displayColumns } = useDisplayColumns();

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleDisplayColumn = (data: DisplayColumnsFormFields) => {
        setLoading(true);

        setSelectedColumns(data.columns || []);
        displayColumns(data, COLUMN_OPTIONS);

        setLoading(false);
        handleCloseModal();
    };

    useEffect(() => {
        if (isOpen) {
            reset({
                columns: selectedColumns,
            });
        }
    }, [isOpen, selectedColumns]);

    return (
        <Modal open={ isOpen } fullWidth={ true } maxWidth="lg">
            <ModalTitle onClose={ onClose }>{ MODAL_TITLE_DISPLAY_COLUMNS }</ModalTitle>
            <DialogContent dividers={ true }>
                <Grid2 spacing={ 2 } container padding={ 2 }>
                    <Grid2 xs={ 12 }>
                        <MultipleSelect
                            options={ COLUMN_OPTIONS }
                            name={ DISPLAY_COLUMNS_FIELDS_KEYS.columns as keyof DisplayColumnsFormFields }
                            control={ control }
                            size="small"
                            selectedValues={ columnsField.value }
                            label={ PROPERTY_DISPLAY_COLUMNS }
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={ () => reset() }>Сбросить</Button>
                <Button onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    loading={ loading }
                    onClick={ handleSubmit(handleDisplayColumn) }
                    disabled={ !isValid }
                >
                    Подтвердить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default DisplayColumnsModal;
