import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { PaymentDocumentT } from 'types/PaymentDocument';

export type PaymentDocumentsResponseT = {
    paymentDocuments: PaymentDocumentT[];
    paymentDocumentsCount: AggregateT;
}

export type PaymentDocumentResponseT = {
    paymentDocument: PaymentDocumentT;
}

export const PAYMENT_DOCUMENTS_QUERY = gql`
    query PaymentDocuments(
        $limit: Int,
        $offset: Int,
        $orderBy: payment_document_order_by!,
        $where: payment_document_bool_exp!,
    ) {
        paymentDocuments: payment_document(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            number
            datetime
            organization {
                id
                name
            }
            payments {
                id
                payment_document {
                    id
                    number
                }
                datetime
                client_id
                payer
                place
                operation
                service
                reason
                payment_method {
                    id
                    title
                }
                performer
                user_id
                comment
                organization_location {
                    id
                    name
                }
                total
                payment_in_fiat
                payment_in_bonus
                payment_type {
                    id
                    title
                }
                client_datum {
                    client {
                        full_name
                    }
                }
            }
        }
        paymentDocumentsCount: payment_document_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const PAYMENT_DOCUMENT_QUERY = gql`
    query PaymentDocument($id: String!) {
        paymentDocument: payment_document_by_pk(id: $id) {
            id
            id
            number
            datetime
            organization {
                id
                name
            }
            comment
            payments {
                id
                payment_document {
                    id
                    number
                }
                datetime
                client_id
                payer
                place
                operation
                service
                reason
                payment_method {
                    id
                    title
                }
                performer
                user_id
                comment
                organization_location {
                    id
                    name
                }
                total
                payment_in_fiat
                payment_in_bonus
                payment_type {
                    id
                    title
                }
                client_datum {
                    client {
                        full_name
                    }
                }
            }
        }
    }
`;
