import { GridColumnVisibilityModel } from '@mui/x-data-grid-premium';
import { DisplayColumnsFormFields } from 'page/Price/components/DisplayColumnsModal/formData';
import { useStore } from 'store';
import { OptionT } from 'types/Option';

const useDisplayColumns = () => {
    const { setColumnVisibilityModel } = useStore('price');
    const { priceSalesRounds } = useStore('priceSalesRound');

    const displayColumns = (data: DisplayColumnsFormFields, columnOptions: OptionT[]) => {
        const { columns = [] } = data;

        const model: GridColumnVisibilityModel = columns.length
            ? columnOptions.reduce((acc, column) => {
                priceSalesRounds.forEach((_, index) => {
                    Object.assign(acc, {
                        [`${column.value}-${index}`]: columns.some((item) => item === column.value),
                    });
                });

                return acc;
            }, {})
            : {};

        setColumnVisibilityModel(model);
    };

    return {
        displayColumns,
    };
};

export default useDisplayColumns;
