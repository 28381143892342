import React, { FC, MouseEventHandler } from 'react';
import { Button, DialogActions, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal/index';
import { useStore } from 'store';

type PropsT = {
    id: string;
    title: string;
    onArchiveButtonClick: () => void;
};

const ArchiveModal: FC<PropsT> = observer((props) => {
    const { id, title, onArchiveButtonClick } = props;
    const { itemId, onClose } = useStore('archiveItemModal');

    const handleArchiveButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        onArchiveButtonClick();
        onClose();
    };

    return (
        <Modal open={ itemId === id } onClose={ onClose }>
            <DialogTitle>Вы действительно хотите перенести в архив { title || 'выбранный элемент' }?</DialogTitle>
            <DialogActions>
                <Button variant="outlined" onClick={ onClose }>Отмена</Button>
                <Button onClick={ handleArchiveButtonClick } variant="contained" autoFocus color="error">Перенести</Button>
            </DialogActions>
        </Modal>
    );
});

export default ArchiveModal;
