import { DATA_GRID_PAGE_SIZES_EXTEND, PRICE_DATA_GRID_INITIAL_STATE } from 'constants/dataGrid';

import React, {
    FC, memo,
} from 'react';
import AddIcon from '@mui/icons-material/Add';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { TabContext as BaseTabContext, TabList } from '@mui/lab';
import {
    Box, Button, Tab,
} from '@mui/material';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import DataGrid from 'shared/component/DataGrid';
import { DataGridPropsT } from 'types/DataGrid';

import { Wrapper } from '../../Main/styles';
import { StyledPricePageTabPanel, StyledPricePageWrap } from '../domain/Styled';

import useTabContext from './useTabContext';

type PropsT = {
    tab: string;
    handleTabChange: (newTab: string) => void;
    fetchPrices: Function;
    loading: boolean;
    fetchSalesRounds: (priceTypeId?: string | undefined) => Promise<void>;
    priceSeasonId: string;
} & DataGridPropsT;

const TabContext: FC<PropsT> = observer((props) => {
    const {
        tab,
        loading,
        pageSize,
        setPageSize,
        sortModel,
        page,
        setPage,
        setSortModel,
        filterModel,
        setFilterModel,
    } = props;
    const {
        columnVisibilityModel,
        showColumns,
        prices,
        priceTypes,
        count,
        handleRefresh,
        deleteMultiplePrice,
        openFilPriceModal,
        openCreatePriceModal,
        setColumnVisibilityModel,
        changeTab,
        groupHeaderData,
        headerData,
    } = useTabContext(props);

    return (
        <Wrapper>
            <BaseTabContext value={ tab }>
                <Box sx={ { borderBottom: 1, borderColor: 'divider' } }>
                    <TabList
                        scrollButtons={ true }
                        variant="scrollable"
                        onChange={ changeTab }
                    >
                        {
                            tab && priceTypes.map((item) => (
                                <Tab label={ item.title } value={ item.id } key={ item.id } />
                            ))
                        }
                    </TabList>
                </Box>
                <StyledPricePageWrap>
                    <ControlPanel>
                        <Button variant="outlined" size="small" onClick={ openCreatePriceModal } startIcon={ <AddIcon /> }>создать строки</Button>
                        <Button variant="outlined" size="small" onClick={ openFilPriceModal } startIcon={ <PlaylistAddIcon /> }>Заполнить тарифы</Button>
                    </ControlPanel>
                    {
                        priceTypes.map(({ id }) => (
                            <StyledPricePageTabPanel value={ id } key={ id } sx={ { padding: 0 } }>
                                <DataGrid
                                    rows={ prices }
                                    rowCount={ count }
                                    columns={ headerData }
                                    loading={ loading }
                                    rowsPerPageOptions={ DATA_GRID_PAGE_SIZES_EXTEND }
                                    pageSize={ pageSize }
                                    page={ page }
                                    setPageSize={ setPageSize }
                                    setPage={ setPage }
                                    setSortModel={ setSortModel }
                                    setFilterModel={ setFilterModel }
                                    columnGroupingModel={ groupHeaderData }
                                    sortModel={ sortModel }
                                    filterModel={ filterModel }
                                    multipleDeleteAction={ deleteMultiplePrice }
                                    multipleRefreshAction={ handleRefresh }
                                    className="price-data-grid"
                                    initialState={ PRICE_DATA_GRID_INITIAL_STATE }
                                    showColumns={ showColumns }
                                    columnVisibilityModel={ columnVisibilityModel }
                                    onColumnVisibilityModelChange={ (model) => setColumnVisibilityModel(model) }
                                />
                            </StyledPricePageTabPanel>
                        ))
                    }
                </StyledPricePageWrap>
            </BaseTabContext>
        </Wrapper>
    );
});

export default memo(TabContext);
